import '../style/RBLang.css';
import React from "react";
import XMLViewer from "react-xml-viewer";

const customTheme = {
    attributeKeyColor: "#0074D9",
    attributeValueColor: "#2ECC40"
};

function RBLang({rblang}) {
    const reset = () => {
        window.location.reload();
    }

    return (
        <div className='container-fluid d-flex vh-100'>
                <div className='graphContainer'>
                <button className="copyButton" onClick={() => {navigator.clipboard.writeText(rblang)}}>Copy RBLang</button>
                <button className="resetButton" onClick={reset}>Reset</button>

                <br/>
                <XMLViewer xml={rblang} theme={customTheme} />
            </div>
        </div>
    );
}


export default RBLang;

/*

<div className='row'>
                <div className='column'>
                <p className='costText'>
                    Total tokens used: 0<br />
                    Total cost: $0
                </p>
                </div>
                <div className='column'>
                    <button className="clearButton">Reset</button>
                </div>
            </div>
        </div>
    </div>

    */