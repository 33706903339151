import './style/App.css';
import React, { useState } from "react";
import Chat from "./components/Chat"
import RBLang from "./components/RBLang"


function App(){
  const [XML, setXML] = useState("<?xml version=\"1.0\" encoding=\"utf-8\"?><rbl:kb xmlns:rbl=\"http://rbl.io/schema/RBLang\"></rbl:kb>")

  const receiveRBLang = (data) => {
    setXML(data);
  }

  return (
    <div className='container-fluid d-flex vh-100'>
      <div className="row flex-fill">
        <div className="col-6 align-self-end">
          <div className="instructions">
          <img src="./rb_logo.png" className="headerGraphic" alt="Rainbird Logo"/><br/>
            <p className="noteText">
              Note that this currently only supports very simple rules, only supports string types and number types, and only knows about comparative expressions.
            </p>          
          </div>
          <Chat
            setRBLang={receiveRBLang}
          />
        </div>
        <div className="col-6 align-self-end">
          <RBLang 
            rblang={XML}
          />
        </div>
      </div>
    </div>
  );
}


export default App;

/*

       <div className="col-1">
          <img src="./rb_logo.png" className="headerGraphic" alt="Rainbird Logo"/><br/>
          <p className="noteText">
            Note that this currently only supports very simple rules, only supports string types and number types, and only knows about comparative expressions.
          </p>
        </div>

*/        