import '../style/Chat.css';
import React, { useState } from "react";
import { BallTriangle } from  'react-loader-spinner'

const OPENAI_API_KEY = "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";

const DEFAULT_PARAMS = {
  "model": "gpt-4",
  "temperature": 0.3,
  "max_tokens": 800,
  "top_p": 1,
  "frequency_penalty": 0,
  "presence_penalty": 0
}


// https://api.openai.com/v1/chat/completions

const token_price = 0.0200;

function Chat({setRBLang}){
    const [state, setState] = useState("<?xml version=\"1.0\" encoding=\"utf-8\"?><rbl:kb xmlns:rbl=\"http://rbl.io/schema/RBLang\"></rbl:kb>")    
    const { graph } = state;

    const [isProcessing, setIsLoading] = useState(false);
    const [history, setHistory] = useState("")
    const [errorMsg, setError] = useState({message: ""});

    const queryPrompt = (prompt) => {
        fetch('prompts/main.prompt')
          .then(response => response.text())
          .then(text => text.replace("$prompt", prompt))
          .then(text => text.replace("$state", JSON.stringify(state)))
          .then(prompt => {
            console.log(prompt)
            //setIsLoading(true);
            const params = { ...DEFAULT_PARAMS, messages: [{"role": "user", "content": prompt}] };
      
            const requestOptions = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(OPENAI_API_KEY)
              },
              body: JSON.stringify(params)
            };

            fetch('https://api.openai.com/v1/chat/completions', requestOptions)
              .then(response => response.json())
              .then(data => {
                console.log(data);
                const new_graph = data.choices[0].message.content;
                console.log(new_graph);
                //console.log(new_graph);
               // new_graph.graph.tokens = newTokens + graph.tokens;
               // new_graph.graph.cost = (new_graph.graph.tokens / 1000) * token_price;
                setRBLang(new_graph);
                setState(new_graph);
                setIsLoading(false);
                document.body.style.cursor = 'default';
                document.getElementsByClassName("generateButton")[0].disabled = false;
                document.getElementsByClassName("searchBar")[0].value = "";
              }).catch(err => {
                console.log(err);

                setError({
                 message: "Sorry - something went wrong. Please try again."
                });
      
                document.body.style.cursor = 'default';
                document.getElementsByClassName("generateButton")[0].disabled = false;
               setIsLoading(false);
              });
          })
      }

    const createKM = () => {
        setIsLoading(true);
        document.getElementsByClassName("generateButton")[0].disabled = true;
     
        const prompt = document.getElementsByClassName("searchBar")[0].value;
        setHistory(history + "> " + prompt + "\n");
        document.getElementsByClassName("searchBar")[0].value = "";
        setError({message: ""});

        queryPrompt(prompt);
    }      

    return (
    <div className='chatContainer'>
        <BallTriangle
            height={250}    
            width={120}
            radius={5}
            color="#455284"
            ariaLabel="ball-triangle-loading"
            wrapperClass="working"
            wrapperStyle=""
            visible={isProcessing}
        />        
        <pre>
            {history}
        </pre>            
        {errorMsg.message}
        <form className='inputContainer input-group mb-3'>
            <input className="searchBar form-control" type="text" aria-describedby="generateButton" id="generateButton" placeholder="Describe your expertise..."></input>
            <button className="generateButton btn btn-outline-secondary" id="generateButton" onClick={createKM}>Generate</button>
        </form>
    </div>
    );
}

export default Chat;

//         <label className="form-label cost">Total Cost: ${graph.cost}</label>
